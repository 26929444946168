












import { Vue, Prop, Component } from 'vue-property-decorator'
import { toDate, parseISO, isToday, format } from 'date-fns'

import { EnhancedPost, Term } from '~base/common/types'

@Component
export default class PostMeta extends Vue {
  @Prop({ type: Object, required: true }) readonly post!: EnhancedPost
  @Prop({
    type: String,
    default: '',
  })
  readonly categories!: string

  get categoriesMarkup(): string {
    const [c]: Term[] = this.post.relevantCategories || []
    if (!c) return ``
    return `<span class='category-${c.slug}'>${c.name}</span>`
  }

  get timeForHumans(): string {
    const d = toDate(parseISO(`${this.post.dateGmt}+0`))
    if (isToday(d)) {
      return format(d, this.$t('dates.nice').toString())
    }
    return format(d, this.$t('dates.format').toString())
  }
}
