<template>
  <article v-if="post" class="most-read-article" @click.prevent="navigate()">
    <div class="most-read-article__counter">
      <span>{{ atIndex }}</span>
    </div>
    <div class="content">
      <PostTitle
        :title="post.title"
        :permalink="permalink"
        class="most-read-article__title"
      />
      <PostMeta :post="post" />
    </div>

    <LazyPicture
      v-if="post.thumbnail"
      :picture="post.thumbnail"
      :title="post.title"
      :is-free="post.isFree"
    />
  </article>
</template>

<script>
import LazyPicture from '~components/utils/LazyPicture.vue'
import PostTitle from '~components/content/PostTitle.vue'
import PostMeta from '~components/content/PostMeta.vue'

import { undomainify } from '~plugins/utils'

export default {
  components: {
    LazyPicture,
    PostTitle,
    PostMeta,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    atIndex: {
      type: Number,
      required: true,
    },
    asAd: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    permalink() {
      const permalink = `https://${this.article.url}`
      return this.asAd ? permalink : undomainify(permalink)
    },
    post() {
      return this.article.post || {}
    },
  },
  methods: {
    navigate() {
      if (this.asAd) {
        window.open(this.permalink, '_blank')
      } else {
        this.$router.push(this.permalink)
      }
    },
  },
}
</script>

<style>
@import '~components/content/widgets/mostread/MostReadArticlesItem.css';
</style>
