









































import { Component, Vue } from 'vue-property-decorator'
import DropDown from '~components/utils/DropDown.vue'
import { ApolloOperationVariables } from '~base/common/types'
import MostReadArticlesItem from '~components/content/widgets/mostread/MostReadArticlesItem.vue'
import LocalityItem from '~components/content/widgets/locality/LocalityItem.vue'

@Component({
  components: {
    DropDown,
    MostReadArticlesItem,
    LocalityItem,
  },
  apollo: {
    search: {
      query: require('~base/graphql/queries/FetchPostsForLocality.gql'),
      variables(): ApolloOperationVariables {
        return {
          first: 5,
          localities: [this.$store.getters['weather/selectedRegion']],
        }
      },
    },
  },
})
export default class LocalityWidget extends Vue {
  private township: string

  constructor() {
    super()
    this.township = this.$store.getters['weather/selectedRegionNiceName']
  }

  get localityList() {
    return this.$store.getters['weather/townships']
  }

  get localityLink() {
    return `/${this.$store.getters['weather/selectedRegion']}`
  }

  onChange(e: string) {
    this.township = e
    this.$store.dispatch('weather/changeRegion', e)
  }
}
