<template>
  <!-- eslint-disable vue/html-indent -->
  <figure class="lazy-picture relative">
    <div class="image-wrapper">
      <img
        ref="lazyloadedImage"
        :alt="title"
        :lazy-srcset="picture.srcset || ''"
        :lazy-sizes="picture.sizes || ''"
        :lazy-src="picture.src"
        :width="picture.width"
        :height="picture.height"
        loading="lazy"
        :src="lazyImg"
      />
      <noscript inline-template>
        <img
          :alt="title"
          :srcset="picture.srcset || ''"
          :sizes="picture.sizes || ''"
          :src="picture.src"
          :width="picture.width"
          :height="picture.height"
        />
      </noscript>
    </div>
    <Plus v-if="!isFree" class="absolute top-0 left-0 mt-2 h-8 w-8" />
    <svg
      v-if="hasVideo"
      class="absolute bottom-0 left-0 m-2 h-6 w-6"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
        fill="white"
      />
      <path
        d="M12.5172 20.5862L19.9655 15L12.5172 9.41379V20.5862ZM15 2.58621C8.14759 2.58621 2.58621 8.14759 2.58621 15C2.58621 21.8524 8.14759 27.4138 15 27.4138C21.8524 27.4138 27.4138 21.8524 27.4138 15C27.4138 8.14759 21.8524 2.58621 15 2.58621ZM15 24.931C9.52552 24.931 5.06897 20.4745 5.06897 15C5.06897 9.52552 9.52552 5.06897 15 5.06897C20.4745 5.06897 24.931 9.52552 24.931 15C24.931 20.4745 20.4745 24.931 15 24.931Z"
        fill="#E30613"
      />
    </svg>

    <div class="icon-on-picture">
      <slot />
    </div>
  </figure>
</template>

<script>
export default {
  props: {
    picture: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    hasVideo: {
      type: Boolean,
      default: false,
    },
    isFree: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lazyImg() {
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8/x8AAuMB8DtXNJsAAAAASUVORK5CYII='
    },
  },
  mounted() {
    if ('loading' in HTMLImageElement.prototype) {
      this.$refs.lazyloadedImage.src = this.picture.src
      this.$refs.lazyloadedImage.srcset = this.picture.srcset || ''
      this.$refs.lazyloadedImage.sizes = this.picture.sizes || ''
    } else {
      this.$store.dispatch('news/lazyLoadImage', this.$refs.lazyloadedImage)
    }
  },
}
</script>

<style>
.lazy-picture {
  @apply relative;
  box-sizing: content-box;

  .image-wrapper {
    @apply aspect-h-9 aspect-w-16;
  }

  img {
    @apply h-full w-full object-cover object-center;
  }

  .icon-on-picture {
    @apply absolute top-2 left-2 h-6 w-6 drop-shadow filter;
  }
}
</style>
