



















import { Component, Prop, Vue } from 'vue-property-decorator'
import LazyPicture from '~components/utils/LazyPicture.vue'
import PostTitle from '~components/content/PostTitle.vue'
import PostMeta from '~components/content/PostMeta.vue'
import { SearchResultEntry, EnhancedPost } from '~base/common/types'
import { enhance } from '~plugins/utils'

@Component({
  components: {
    LazyPicture,
    PostTitle,
    PostMeta,
  },
})
export default class LocalityItem extends Vue {
  @Prop({ type: Object, required: true }) readonly post!: SearchResultEntry
  private article: EnhancedPost | undefined

  constructor() {
    super()
    this.article = enhance(this.post, this.$config)
  }

  navigate() {
    this.$router.push(this.article!.link)
  }
}
